import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import badges from "../../images/zoominfo/badges.png";
import social1 from "../../images/zoominfo/social-1.png";
import social2 from "../../images/zoominfo/social-2.png";
import social3 from "../../images/zoominfo/social-3.png";
import social4 from "../../images/zoominfo/social-4.png";
import before from "../../images/zoominfo/before.png";
import after from "../../images/zoominfo/after.png";
import onboarding from "../../images/zoominfo/onboarding.png";
import video from "../../images/zoominfo/full_hd.mp4";
import homepage from "../../images/zoominfo/homepage.png";
import chatbot from "../../images/zoominfo/chatbot.png";
import pricing from "../../images/zoominfo/pricing.png";
import engagepage from "../../images/zoominfo/engage-home.png";
import opspage from "../../images/zoominfo/operations.jpg";
import talentpage from "../../images/zoominfo/recruiting.jpg";
import image1 from "../../images/zoominfo/image-1.png";
import image2 from "../../images/zoominfo/image-2.png";
import image3 from "../../images/zoominfo/image-3.png";
import poster from "../../images/zoominfo/report.png";
import phones from "../../images/zoominfo/phones.png";

import guide1 from "../../images/zoominfo/guide1.png";
import guide2 from "../../images/zoominfo/guide2.png";
import guide3 from "../../images/zoominfo/guide3.png";
import guide4 from "../../images/zoominfo/guide4.png";
import guide5 from "../../images/zoominfo/guide5.png";
import guide6 from "../../images/zoominfo/guide6.png";

import rotate1 from "../../images/zoominfo/sales.png";
import rotate2 from "../../images/zoominfo/operations.png";
import rotate3 from "../../images/zoominfo/mktg.png";
import rotate4 from "../../images/zoominfo/zoominfo-banner.png";
import rotate5 from "../../images/zoominfo/talent.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class ZoomInfo extends React.Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            currentImage: 0,
            images: [
                rotate4, rotate1, rotate3, rotate5, rotate2
            ]
        };
    }

    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 1000);
    }

    render() {
        return (
                <Layout pageName="zoominfo">
                    <Seo
                            title={'ZoomInfo'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-6xl sm:text-9xl leading-none sm:-mt-4">ZoomInfo</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Website Design</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Art Direction</li>
                                            </ul>
                                        </div>
                                        <div className=" ml-12">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2021 – 2022</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">In 2021, our in-house creative team pitched a brand refresh to leadership. I helped lead the effort to completely overhaul our brand design and presence in the market. This included a new set of 8 logos for our solutions and products, an extensive brand guidelines document, onboarding documents, deck templates, social, emails, swag and more.</p>
                                        <p className="leading-normal mt-4">Last but not least, we launched a redesigned website with 100+ new pages. This also included working with our team of talented designers to revamp over 60 product illustrations and animations.</p>
                                        <p className="leading-normal mt-4">The launch of the new website allowed the marketing team to hit 101% of our MQL target and 125% of our pipeline target, and accounted for 57% of the company's overall pipeline creation.</p>
                                        <a href="https://www.zoominfo.com/" target="_blank" rel="noreferrer" className="mt-8 block featured-color hover:underline">Launch Website -></a>
                                    </div>
                                </div>
                                <FadeInSection>
                                    <div className="mt-16">
                                        <img
                                                src={this.state.images[this.state.currentImage]}
                                                alt="project images"
                                        />
                                    </div>
                                </FadeInSection>
                            </div>
                            <FadeInSection>
                                <div className="extra-large-wrapper sm:grid grid-cols-2 gap-4 mt-16 sm:mt-48">
                                    <h2 className="font-mono italic text-2xl">Re-imagined Brand House</h2>
                                    <p className="mt-4">ZoomInfo has acquired 8 companies since I first joined, leading to the inevitable mismatched group of logos and brands. I led the design effort to create a single, unified brand house to accommodate not only our current solutions and products, but pave the way for future growth as well.</p>
                                </div>
                            </FadeInSection>
                                <div className="extra-large-wrapper mt-8 sm:mt-16">
                                    <FadeInSection>
                                        <img src={before} alt="before" className=""/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={after} alt="after" className="mt-4 sm:mt-8"/>
                                    </FadeInSection>
                                </div>
                            <div className="extra-large-wrapper mt-16 sm:mt-32 grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                <FadeInSection>
                                    <img src={homepage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={pricing} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={opspage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={talentpage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                            </div>
                            <FadeInSection>
                                <div className="extra-large-wrapper mt-16 sm:mt-32">
                                    <img src={phones} alt="" className=""/>
                                </div>
                            </FadeInSection>
                            <div className="extra-large-wrapper mt-16 sm:mt-32 sm:grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                <FadeInSection>
                                    <img src={chatbot} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={engagepage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                </FadeInSection>
                            </div>
                        </section>
                        <section className="bg-black py-16 sm:py-32 mt-16 sm:mt-32">
                            <div className="extra-large-wrapper sm:grid-cols-2 grid gap-8">
                                <FadeInSection>
                                    <img src={poster} alt="" className=""/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={badges} alt="" className=""/>
                                </FadeInSection>
                            </div>
                            <div className="extra-large-wrapper grid-cols-2 sm:grid-cols-4 grid gap-4 mt-16 sm:mt-32">
                                <FadeInSection>
                                    <div className="rounded-2xl overflow-hidden border-4 border-darkgrey">
                                        <img src={social1} alt="" className="max-w-full -mb-1"/>
                                    </div>
                                </FadeInSection>
                                <FadeInSection>
                                    <div className="rounded-2xl overflow-hidden border-4 border-darkgrey">
                                        <img src={social2} alt="" className="max-w-full -mb-1"/>
                                    </div>
                                </FadeInSection>
                                <FadeInSection>
                                    <div className="rounded-2xl overflow-hidden border-4 border-darkgrey">
                                        <img src={social3} alt="" className="max-w-full -mb-1"/>
                                    </div>
                                </FadeInSection>
                                <FadeInSection>
                                    <div className="rounded-2xl overflow-hidden border-4 border-darkgrey leading-none">
                                        <img src={social4} alt="" className="max-w-full -mb-1"/>
                                    </div>
                                </FadeInSection>
                            </div>
                            <FadeInSection>
                                <div className="extra-large-wrapper mt-16 sm:mt-32">
                                    <img src={onboarding} alt="" className=""/>
                                </div>
                            </FadeInSection>
                            <div className="extra-large-wrapper mt-16 sm:mt-32 grid gap-4 grid-cols-2">
                                <FadeInSection>
                                <img src={guide1} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                <img src={guide2} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                <img src={guide3} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                <img src={guide4} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                <img src={guide5} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                                <FadeInSection>
                                <img src={guide6} alt="" className="w-full rounded-xl border border-darkgrey"/>
                                </FadeInSection>
                            </div>
                        </section>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-32 text-right">
                                <FadeInSection>
                                    <video className="rounded-2xl" autoPlay loop muted>
                                        <source src={video} type="video/mp4"></source>
                                    </video>
                                    <p className="text-xs mt-4">Animation by <a href="https://meganmccall.com/" target="_blank" rel="noreferrer">Megan McCall</a></p>
                                </FadeInSection>
                                <div className="grid grid-cols-3 gap-8 mt-16 sm:mt-32 items-center content-evenly justify-center justify-evenly">
                                    <FadeInSection>
                                        <img src={image1} alt="" className=""/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={image3} alt="" className=""/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={image2} alt="" className=""/>
                                    </FadeInSection>
                                </div>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/neverbounce" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — neverbounce
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default ZoomInfo;
